<template>
    <ParametrosProducaoForm />
</template>

<script>
    import ParametrosProducaoForm from '../../components/parametro/ParametrosProducaoForm.vue';

    export default {
        components: { ParametrosProducaoForm },
        setup() {
            
        },
    }
</script>
